import axios, { AxiosInstance } from 'axios';
import Base64 from 'crypto-js/enc-base64';
import sha256 from 'crypto-js/sha256';
import * as _ from 'lodash';
import { makeAutoObservable, ObservableMap, values } from 'mobx';
import * as qs from 'qs';

import { Auth0ContextInterface } from '@auth0/auth0-react';

import config from '../infra/config';
import { ILocation, Data, ICustomer, SavedCustomer, Attribute, Capacity } from '../types';
import RootStore from './RootStore';

export class DataStore {
    public customers = new ObservableMap<string, SavedCustomer>();
    public attributes: Attribute[] = [];
    public capacities: Capacity[] = [];

    http?: AxiosInstance;
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeAutoObservable(this, { rootStore: false, http: false });
    }

    public async init(options: {
        getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'];
        logout: Auth0ContextInterface['logout'];
    }) {
        this.http = axios.create({
            baseURL: config.api.url,
            headers: { 'Content-Type': 'application/json' },
        });
        this.http.interceptors.request.use(async (req) => {
            const token = await options.getAccessTokenSilently({ timeoutInSeconds: 5 });
            req.headers.Authorization = 'Bearer ' + token;
            // Add header so customer-db can identify the client
            req.headers['vinka-client'] = 'customer-ui';
            return req;
        });
        this.http.interceptors.response.use(undefined, async (err) => {
            if (err?.response?.status === 401) {
                options.logout();
            } else {
                throw err;
            }
        });

        this.http.get<Data<Attribute[]>>('/attributes').then((response) => {
            this.attributes = response.data.data;
        });
        this.http.get<Data<Capacity[]>>('/capacities').then((response) => {
            this.capacities = response.data.data;
        });
    }

    public async getCustomers(
        search?: string,
        queryOnly?: true
    ): Promise<readonly SavedCustomer[]> {
        const dbs: string[] = config.app.dbs;
        const params: any = {
            db: dbs,
            search,
        };
        const queryString = qs.stringify(params, { arrayFormat: 'comma' });
        const response = await this.http!.get<Data<SavedCustomer[]>>(`/customer?${queryString}`);
        if (queryOnly) {
            return response.data.data;
        } else {
            this.customers.merge(_.keyBy(response.data.data, 'id'));
            return values(this.customers);
        }
    }

    public async getCustomer(id: string): Promise<SavedCustomer> {
        const cached = this.customers.get(id);
        if (cached) {
            return cached;
        }

        const response = await this.http!.get<Data<SavedCustomer>>('/customer/' + id);
        const customer = response.data.data;
        this.customers.set(customer.id, customer);

        return this.customers.get(customer.id)!;
    }

    public async getCustomerBySsn(ssn: string): Promise<SavedCustomer | undefined> {
        const ssnHash = Base64.stringify(sha256(ssn));
        const dbs: string[] = config.app.dbs;
        const params: any = {
            db: dbs,
        };
        params['ssn-hash'] = ssnHash;
        if (dbs.includes('takso')) {
            // ssnHash not supported in external db, must use plain ssn
            params.ssn = ssn;
        }

        const queryString = qs.stringify(params, { arrayFormat: 'comma' });
        try {
            const response = await this.http!.get<Data<SavedCustomer>>(`/customer?${queryString}`);
            return response.data.data[0];
        } catch (e: any) {
            if (e.response.status === 404) {
                return undefined;
            } else {
                throw e;
            }
        }
    }

    public async addCustomer(data: ICustomer): Promise<SavedCustomer> {
        const response = await this.http!.post<Data<SavedCustomer>>('/customer', { data });
        const savedCustomer = response.data.data;
        this.customers.set(savedCustomer.id, savedCustomer);

        return this.customers.get(savedCustomer.id)!;
    }

    public async upsertCustomer(customer: SavedCustomer): Promise<SavedCustomer> {
        let response;
        if (customer.id) {
            response = await this.http!.patch<Data<SavedCustomer>>(
                '/customer/' + encodeURIComponent(customer.id),
                {
                    data: customer,
                }
            );
        } else {
            response = await this.http!.post<Data<SavedCustomer>>('/customer', {
                data: customer,
            });
        }
        const savedCustomer = response.data.data;
        this.customers.set(savedCustomer.id, savedCustomer);

        return this.customers.get(savedCustomer.id)!;
    }

    public async archiveCustomer(id: string) {
        await this.http!.delete('/customer/' + id);
        this.customers.delete(id);
    }

    // ----------------------------------
    // MISC
    // ----------------------------------

    public async geocode(search: string): Promise<ILocation[]> {
        const response = await this.http!.get('/geocode', { params: { search } });
        const results: any[] = response.data.data;

        return results.map((tomtomResult) => ({
            lat: tomtomResult.position.lat,
            lng: tomtomResult.position.lon,
            city: tomtomResult.address.municipality,
            street: tomtomResult.address.streetName,
            streetNumber: tomtomResult.address.streetNumber,
            alias: tomtomResult.poi?.name,
        }));
    }
}
