import EnvUtils, { EnvUtilsOptions } from '@vinka/env-utils';

const envUtilsOptions: EnvUtilsOptions = {
    prefix: 'REACT_APP_CUSTOMER_UI_',
};

// In production build, we get the env values from window. Consult README for more info.
if (process.env.NODE_ENV === 'production') {
    const envObject = (window as any)._env_;

    if (!envObject) {
        throw Error('Environment variables have not been configured to window in production env');
    }

    envUtilsOptions.envObject = envObject;
}

const env = new EnvUtils(envUtilsOptions);

const config = Object.freeze({
    localization: {
        locale: env.getValue<'en' | 'fi'>({
            name: 'LOCALE',
            defaults: {
                '*': 'fi',
            },
        }),
        timezone: env.getValue<string>({
            name: 'TIMEZONE',
            defaults: {
                '*': 'Europe/Helsinki',
            },
        }),
    },
    auth0: {
        domain: env.getValue<string>({
            name: 'AUTH0_DOMAIN',
            defaults: {
                'production,development': 'vinka.eu.auth0.com',
            },
        }),
        clientId: env.getValue<string>({
            name: 'AUTH0_CLIENT_ID',
            defaults: {
                'production,development': 'tDF93SIm0LPBB1LN0USVhkkIX8YWYGwX',
            },
        }),
        audience: env.getValue<string>({
            name: 'AUTH0_AUDIENCE',
            defaults: {
                '*': 'https://customer-db.vinka.cloud',
            },
        }),
    },
    api: {
        url: env.getValue<string>({
            name: 'API_URL',
            defaults: {
                development: 'http://localhost:9130',
            },
        }),
    },
    app: {
        dbs: env.getValue<string[]>({
            name: 'CUSTOMER_DBS',
            translator: 'array',
            defaults: {
                '*': ['vinka'], // ['vinka', 'takso']
            },
        }),
        excessTypes: env.getValue<{
            [excesssType: string]: { i18n: { fi: string; en?: string }; allowEdit: boolean };
        }>({
            name: 'EXCESS_TYPES',
            translator: JSON.parse,
            defaults: {
                '*': {
                    voucher: { i18n: { fi: 'Maksusitoumus' }, allowEdit: false },
                    excempt: { i18n: { fi: 'Matkakatto täynnä' }, allowEdit: false },
                    invoice: { i18n: { fi: 'Laskutusoikeus' }, allowEdit: true },
                    'in vehicle': { i18n: { fi: 'Maksetaan autossa' }, allowEdit: true },
                },
            },
        }),
    },
});

export default config;
