export default {
    general: {
        startTyping: 'Aloita kirjoittamalla',
        loading: 'Ladataan...',
        noOptions: 'Ei hakutuloksia',
        address: 'Osoite',
        addressSearch: 'Osoitehaku',
        select: 'Valitse...',
    },
    select: {
        customer: {
            prompt: 'Hae asiakasta',
        },
    },
    form: {
        customer: {
            accounts: 'Asiakkkaan tilit',
            homeAddress: 'Kotiosoite',
            label: {
                customerSearch: 'Asiakashaku',
                firstName: 'Etunimi',
                lastName: 'Sukunimi',
                phoneNumber: 'Puhelinnumero',
                email: 'Sähköposti',
                ssn: 'Henkilötunnus',
                bookingKey: 'Asiakasnumero',
                myTaxi: 'Tuttutaksi',
                prohibitSharing: 'Yhdistelykielto',
                tripType: 'Matkatyyppi',
                accountType: 'Tilityyppi',
                accountId: 'Tili',
                excessType: 'Lisämaksutyyppi',
                allowance: 'Matkaoikeus',
            },
            button: {
                create: 'Luo asiakas',
                update: 'Päivitä asiakas',
                clear: 'Tyhjennä lomake',
                archive: 'Arkistoi asiakas',
            },
            alert: {
                confirmArchiveCustomer: 'Haluatko varmasti arkistoida asiakkaan?',
                customerArchived: 'Asiakas on nyt arkistoitu.',
                archiveFailed: 'Asiakkaan arkistointi epäonnistui!',
            },
            feedback: {
                falsessn: 'Syötä oikea henkilötunnus',
            },
        },
        tripTemplate: {
            label: {
                pickupAddress: 'Nouto-osoite',
                dropAddress: 'Kohdeosoite',
                attributesCapacities: 'Varusteet & Kapasiteetit',
            },
        },
    },
    navbar: {
        login: 'Kirjaudu sisään',
        logoutConfirm: 'Haluatko kirjautua ulos',
    },
};
