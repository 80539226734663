import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';

import { useAuth0 } from '@auth0/auth0-react';

import styles from './Layout.module.css';

import { useRootStore } from '../../providers/RootStoreProvider';
import CustomerForm from '../CustomerForm/CustomerForm';
import NavBar from '../NavBar/NavBar';

function Layout() {
    const { dataStore } = useRootStore();
    const { user, getAccessTokenSilently, logout } = useAuth0();

    useEffect(() => {
        if (user) {
            dataStore.init({ getAccessTokenSilently, logout });
        }
    }, [user]);

    return (
        <div>
            <Container fluid className={styles.vinkaSuperContainer}>
                <NavBar />
                {user && (
                    <Row className={'justify-content-md-center g-0'}>
                        <div className={styles.grid}>
                            <CustomerForm />
                        </div>
                    </Row>
                )}
            </Container>
        </div>
    );
}

export default observer(Layout);
