/* eslint-disable max-len,no-tabs */
import { CSSProperties } from 'react';

export default function VinkaLogo(props: {
    className?: string;
    style?: CSSProperties;
}): JSX.Element {
    return (
        <svg
            className={props.className}
            style={props.style}
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xmlSpace="preserve"
            x="0px"
            y="0px"
            viewBox="0 0 595.28 184"
        >
            <g>
                <path
                    className="st0"
                    d="M235.73,162.87c0-45.3,0-89.7,0-134.68c7.56,0,14.85-0.17,22.12,0.16c1.28,0.06,2.94,1.93,3.62,3.35
		c10.2,21.32,20.25,42.71,30.36,64.07c0.75,1.59,1.68,3.09,2.52,4.63c0.5-0.1,1.01-0.19,1.51-0.29c0-1.86,0-3.72,0-5.58
		c0-20.16,0.1-40.32-0.09-60.47c-0.04-4.38,0.93-6.37,5.8-5.98c5.59,0.44,11.24,0.1,17.17,0.1c0,44.95,0,89.35,0,134.56
		c-7.28,0-14.54,0.21-21.76-0.2c-1.28-0.07-2.77-2.57-3.55-4.23c-10.4-21.97-20.68-43.98-31.01-65.98c-0.6-1.29-1.33-2.52-3.16-3.64
		c-0.12,1.67-0.35,3.34-0.35,5c-0.02,21.32-0.12,42.65,0.08,63.97c0.04,4.24-1.35,5.5-5.43,5.28
		C247.77,162.64,241.96,162.87,235.73,162.87z"
                />
                <path
                    className="st0"
                    d="M550.81,162.59c-20.88,0-41.19,0.31-61.48-0.14c-9.18-0.21-15.71-7.7-15.86-17.42
		c-0.28-17.98-0.32-35.98,0.01-53.96c0.21-11.39,4.48-15.1,15.85-15.14c10.16-0.03,20.32-0.15,30.48,0.06
		c3.99,0.08,5.84-1.01,5.55-5.3c-0.29-4.31-0.26-8.67-0.01-12.98c0.23-3.91-1.22-5.26-5.16-5.2c-13.15,0.18-26.31,0.07-39.47,0.06
		c-1.32,0-2.63-0.11-4.7-0.21c0-7.49-0.09-14.89,0.15-22.28c0.02-0.67,2.19-1.82,3.36-1.83c18.65-0.11,37.31-0.34,55.96,0.05
		c10.57,0.22,15.6,5.61,15.63,16.09c0.13,38.3,0.05,76.61,0.04,114.91C551.16,160.27,550.96,161.23,550.81,162.59z M525.88,118.41
		c0-5.48,0.21-10.97-0.15-16.43c-0.09-1.3-2.03-3.48-3.2-3.55c-6.62-0.35-13.28-0.35-19.91-0.01c-1.25,0.06-3.38,2.22-3.41,3.45
		c-0.27,10.95-0.12,21.91-0.17,32.87c-0.01,2.45,0.82,3.71,3.43,3.69c6.64-0.06,13.29,0.16,19.92-0.17
		c1.22-0.06,3.24-2.16,3.33-3.42C526.08,129.38,525.87,123.89,525.88,118.41z"
                />
                <path
                    className="st0"
                    d="M356.47,162.87c0-45.24,0-89.67,0-134.68c8.18,0,16.13-0.01,24.08,0c3.52,0.01,2.77,2.72,2.78,4.71
		c0.06,13.5,0.03,27,0.03,40.5c0,1.17-0.22,2.39,0.05,3.49c0.43,1.74,1.19,3.39,1.81,5.08c1.17-1.41,2.75-2.64,3.44-4.25
		c6.41-15.01,12.77-30.03,18.88-45.16c1.43-3.54,3.36-4.65,7.07-4.48c6.77,0.31,13.56,0.09,21.19,0.09
		c-7.6,18.15-14.9,35.57-22.43,53.55c2.25,0.44,3.98,0.83,5.73,1.11c9.7,1.56,15.82,7.7,16,17.49c0.36,19.66,0.15,39.33,0.24,58.99
		c0.01,2.54-0.91,3.61-3.5,3.56c-4.66-0.1-9.35-0.27-13.99,0.06c-4.48,0.32-6.11-1.09-6.02-5.81c0.28-14.66-0.07-29.33,0.21-43.99
		c0.09-4.74-1.2-6.56-6.13-6.23c-5.97,0.4-12,0.25-17.99,0.05c-3.52-0.12-4.62,1.25-4.59,4.68c0.13,15-0.18,30,0.17,44.99
		c0.12,5.22-1.59,6.63-6.55,6.34C370.33,162.58,363.67,162.87,356.47,162.87z"
                />
                <path
                    className="st0"
                    d="M47.4,28.19c10.05,0,19.34-0.14,28.63,0.19c1.11,0.04,2.78,2.5,3.08,4.05c4.4,23.05,8.6,46.13,12.87,69.2
		c1.23,6.66,2.53,13.32,4.81,20.27c0.53-1.37,1.22-2.71,1.55-4.12c6.61-28.02,13.26-56.02,19.65-84.09c0.94-4.12,2.33-5.9,6.84-5.63
		c6.95,0.42,13.94,0.11,21.75,0.11c-0.78,3.46-1.37,6.47-2.15,9.42c-10.56,40.06-21.2,80.09-31.66,120.17
		c-1.03,3.94-2.71,5.38-6.83,5.2c-7.65-0.34-15.33-0.28-22.98-0.02c-3.76,0.12-5.15-1.31-5.96-4.9
		c-8.02-35.54-16.22-71.05-24.35-106.57C50.92,43.93,49.24,36.36,47.4,28.19z"
                />
                <path
                    className="st0"
                    d="M198.2,28.17c0,2.5,0,4.29,0,6.09c0,40.78-0.1,81.56,0.12,122.33c0.03,5.08-1.31,6.79-6.39,6.37
		c-5.29-0.43-10.65-0.22-15.97-0.05c-3.11,0.1-4.46-0.81-4.45-4.2c0.09-42.11,0.09-84.22,0.01-126.33
		c-0.01-3.25,1.06-4.37,4.34-4.27C182.98,28.32,190.11,28.17,198.2,28.17z"
                />
            </g>
        </svg>
    );
}
