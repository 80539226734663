import { FinnishSSN } from 'finnish-ssn';
import * as _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import styles from './CustomerForm.module.css';

import { useRootStore } from '../../providers/RootStoreProvider';
import { ICustomer, ILocation, SavedCustomer, IAccount } from '../../types';
import Accounts from '../Accounts';
import AddressInput from '../AddressInput';
import CustomerInput from '../CustomerInput/CustomerInput';

const CustomerForm = () => {
    const { dataStore } = useRootStore();
    const { t } = useTranslation();

    // Customer selected from CustomerInput
    const [customer, setCustomer] = useState<SavedCustomer | undefined>(undefined);

    // Customer properties
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [ssn, setSsn] = useState('');
    const [bookingKey, setBookingKey] = useState<string>('');
    const [ssnInvalid, setSsnInvalid] = useState(false);
    const [enableSubmit, setEnableSubmit] = useState(true);
    const [homeAddress, setHomeAddress] = useState<ILocation | null>(null);
    const [accounts, setAccounts] = useState<IAccount[]>([{ tripType: 'default' }]);

    useEffect(() => {
        setAccounts(customer?.accounts || [{ tripType: 'default' }]);
        setFirstName(customer?.firstName || '');
        setLastName(customer?.lastName || '');
        setPhoneNumber(customer?.phoneNumber || '');
        setEmail(customer?.email || '');
        setSsn(customer?.ssn || '');
        setBookingKey(customer?.bookingKey || '');
        setHomeAddress(customer?.address || null);
    }, [customer]);

    const customerHandler = (e: any) => {
        setCustomer(e);
    };

    const resetForm = () => {
        setCustomer(undefined);
        // Effects take care of resetting the rest of the state when customer changes
    };

    const onSubmit = async () => {
        const formData: ICustomer = {
            firstName: firstName,
            lastName: lastName,
            phoneNumber: phoneNumber,
            email: email || undefined,
            ssn: ssn,
            bookingKey: bookingKey || undefined,
            address: homeAddress || undefined,
            accounts,
        };

        if (customer) {
            const updatableCustomer = _.assign(customer, formData);
            const updatedCustomer = await dataStore.upsertCustomer(updatableCustomer);
            setCustomer(updatedCustomer);
        } else {
            if (formData.firstName && formData.lastName && formData.phoneNumber) {
                const savedCustomer = await dataStore.addCustomer(formData as ICustomer);
                setCustomer(savedCustomer);
            } else {
                // TODO display some sort of validation error
            }
        }
    };

    const onArchive = async () => {
        const shouldArchive = window.confirm(t('form.customer.alert.confirmArchiveCustomer'));
        if (!shouldArchive) {
            return;
        }

        try {
            await dataStore.archiveCustomer(customer!.id);
            window.alert(t('form.customer.alert.customerArchived'));
            resetForm();
        } catch (e) {
            window.alert(t('form.customer.alert.archiveFailed'));
        }
    };

    const ssnHandler = (input) => {
        const ssnInput = input.target.value;
        const validInput = FinnishSSN.validate(ssnInput);

        if (ssnInput.length === 0 || validInput) {
            setSsnInvalid(false);
            setEnableSubmit(true);
        } else {
            setSsnInvalid(true);
            setEnableSubmit(false);
        }
        setSsn(ssnInput);
    };

    const requiredFieldLabel = <span style={{ fontWeight: 'bold', color: 'red' }}>&#x2a; </span>;

    return (
        <Form className={styles.formContainer}>
            {/* CUSTOMER INPUT */}
            <Form.Group className={styles.formGroup}>
                <Form.Label>{t('form.customer.label.customerSearch')}</Form.Label>
                <CustomerInput onChange={customerHandler} value={customer || null} />
            </Form.Group>
            {/* CUSTOMER FORM */}
            <Form.Group className={styles.formGroup}>
                <Row>
                    <Col>
                        <Form.Label>
                            {requiredFieldLabel}
                            {t('form.customer.label.firstName')}
                        </Form.Label>
                        <Form.Control
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            type="text"
                        />
                    </Col>
                    <Col>
                        <Form.Label>
                            {requiredFieldLabel}
                            {t('form.customer.label.lastName')}
                        </Form.Label>
                        <Form.Control
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            type="text"
                        />
                    </Col>
                </Row>
            </Form.Group>
            <Form.Group className={styles.formGroup}>
                <Row>
                    <Col>
                        <Form.Label>
                            {requiredFieldLabel}
                            {t('form.customer.label.phoneNumber')}
                        </Form.Label>
                        <Form.Control
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            type="text"
                        />
                    </Col>
                    <Col>
                        <Form.Label>{t('form.customer.label.email')}</Form.Label>
                        <Form.Control
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            type="text"
                        />
                    </Col>
                    <Col>
                        <Form.Label>{t('form.customer.label.ssn')}</Form.Label>
                        <Form.Control
                            value={ssn}
                            onChange={ssnHandler}
                            type="text"
                            isInvalid={ssnInvalid}
                        />
                        <Form.Control.Feedback type="invalid">
                            {t('form.customer.feedback.falsessn')}
                        </Form.Control.Feedback>
                    </Col>
                </Row>
            </Form.Group>
            <Form.Group className={styles.formGroup}>
                <Row>
                    <Col>
                        <Form.Label>{t('form.customer.label.bookingKey')}</Form.Label>
                        <Form.Control type="text" value={bookingKey} disabled={true} />
                    </Col>
                    <Col sm={6}>
                        <Form.Label>{t('form.customer.homeAddress')}</Form.Label>
                        <AddressInput
                            value={homeAddress}
                            onChange={(e) => {
                                setHomeAddress(e);
                            }}
                        />
                    </Col>
                </Row>
            </Form.Group>
            {/* Accounts */}
            <Form.Group className={styles.formGroup}>
                <Accounts
                    onChange={(a) => setAccounts(_.cloneDeep(a))}
                    accounts={accounts as IAccount[]}
                ></Accounts>
            </Form.Group>
            {/* ACTION BUTTONS */}
            <Form.Group className={styles.formGroup}>
                <Row>
                    <Col>
                        <Button
                            variant="primary"
                            disabled={!enableSubmit}
                            onClick={() => onSubmit()}
                        >
                            {customer
                                ? t('form.customer.button.update')
                                : t('form.customer.button.create')}
                        </Button>
                    </Col>
                    <Col>
                        <Button variant="secondary" onClick={() => resetForm()}>
                            {t('form.customer.button.clear')}
                        </Button>
                    </Col>
                    <Col>
                        <Button variant="danger" onClick={onArchive} disabled={!customer}>
                            {t('form.customer.button.archive')}
                        </Button>
                    </Col>
                </Row>
            </Form.Group>
        </Form>
    );
};
export default observer(CustomerForm);
