// Styles for react-select. Cannot conveniently use css modules for this.
import { NamedProps } from 'react-select';

export const selectStyles: NamedProps['styles'] = {
    menuList: (base: any): any => ({
        ...base,
        fontSize: '0.9em',
        color: '#495057',
    }),
};
