import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Navbar, Dropdown, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IconContext } from 'react-icons';
import * as Fa from 'react-icons/fa';

import { useAuth0 } from '@auth0/auth0-react';

import styles from './NavBar.module.css';

import VinkaLogo from '../VinkaLogo/VinkaLogo';

const settingsIcon = (
    <IconContext.Provider value={{ className: styles.settingsIcon }}>
        <Fa.FaCog />
    </IconContext.Provider>
);

function NavBar() {
    const { t, i18n } = useTranslation();
    const { isAuthenticated, loginWithPopup, logout, user } = useAuth0();

    const [username, setUsername] = useState('');

    // Set pretty username
    useEffect(() => {
        (async () => {
            let shortUsername =
                user?.preferred_username || user?.given_name || user?.nickname || 'User';
            if (shortUsername.length > 15) {
                shortUsername = shortUsername.slice(0, 13) + '...';
            }
            setUsername(shortUsername);
        })();
    }, [user]);

    const setLanguage = (locale: string) => {
        localStorage.setItem('locale', locale);
        i18n.changeLanguage(locale);
    };

    const onUserButtonClick = async () => {
        if (isAuthenticated && window.confirm(t('navbar.logoutConfirm'))) {
            logout();
        } else {
            await loginWithPopup();
        }
    };

    return (
        <Navbar className={`${styles.vinkaNavBar} navbar-dark`}>
            <Navbar.Brand className={styles.vinkaBrand}>
                <VinkaLogo />
                <span id={'vinkaBrandAppNameSpan'}>Customer UI</span>
            </Navbar.Brand>
            <Button
                style={{ marginLeft: 'auto' }}
                variant={'outline-light'}
                onClick={onUserButtonClick}
            >
                {user && user.picture && (
                    <img
                        style={{ maxWidth: '1.25rem', maxHeight: '1.25rem' }}
                        src={user.picture}
                        className={['rounded-circle', 'align-middle'].join(' ')}
                    />
                )}
                <span className={'align-middle'}> {user ? username : t('navbar.login')}</span>
            </Button>
            <Dropdown className={styles.settingsDropdown} drop={'left'}>
                <Dropdown.Toggle
                    className={styles.settingsDropdownToggle}
                    id={'settings-dropdown-toggle'}
                >
                    {settingsIcon}
                </Dropdown.Toggle>
                <Dropdown.Menu alignRight className={styles.settingsDropdownMenu}>
                    <Dropdown.Item
                        className={styles.settingsDropdownItem}
                        onClick={() => setLanguage('fi')}
                    >
                        Suomi
                    </Dropdown.Item>
                    <Dropdown.Item
                        className={styles.settingsDropdownItem}
                        onClick={() => setLanguage('en')}
                    >
                        English
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </Navbar>
    );
}

export default observer(NavBar);
