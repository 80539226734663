export default {
    general: {
        startTyping: 'Please start typing',
        loading: 'Loading...',
        noOptions: 'No results',
        address: 'Address',
        addressSearch: 'Address search',
        select: 'Select...',
    },
    select: {
        customer: {
            prompt: 'Search for customer',
        },
    },
    form: {
        customer: {
            accounts: 'Accounts',
            homeAddress: 'Home address',
            label: {
                customerSearch: 'Customer search',
                firstName: 'First name',
                lastName: 'Last name',
                phoneNumber: 'Phone number',
                email: 'Email',
                ssn: 'SSN',
                bookingKey: 'Booking key',
                myTaxi: 'My Taxi',
                prohibitSharing: 'Prohibit sharing',
                accountType: 'Account type',
                accountId: 'Account ID',
                excessType: 'Excess type',
                allowance: 'Allowance',
            },
            button: {
                create: 'Create customer',
                update: 'Update customer',
                clear: 'Clear form',
                archive: 'Archive customer',
            },
            alert: {
                confirmArchiveCustomer: 'Are you sure you want to archive this customer?',
                customerArchived: 'Customer has been archived.',
                archiveFailed: 'Could not archive customer!',
            },
            feedback: {
                falsessn: 'Enter valid SSN',
            },
        },
        tripTemplate: {
            label: {
                pickupAddress: 'Pickup address',
                dropAddress: 'Drop address',
                attributesCapacities: 'Attributes & Capacities',
            },
        },
    },
    navbar: {
        login: 'Login',
        logoutConfirm: 'Do you want to log out?',
    },
};
