import * as _ from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useTranslation } from 'react-i18next';
import Select, { OptionsType } from 'react-select';

import { useRootStore } from '../../providers/RootStoreProvider';
import { TripAttribute, TripCapacity, Attribute, Capacity, TripTemplate } from '../../types';

type AttributeOrCapacity = Attribute | Capacity;

export interface AttributesCapacitiesInputProps {
    onChange: (attributes: TripAttribute[], capacities: TripCapacity[]) => any;
    tripTemplate: TripTemplate;
}

export const groupAttributesCapacities = (
    settings: AttributeOrCapacity[]
): { capacity: TripCapacity[]; attributes?: TripAttribute[] } => {
    const capacity: TripCapacity[] = settings
        .filter((i) => 'count' in i)
        .map((i) => ({
            unitId: i.id,
            count: (i as Capacity).count,
        }));

    const attributes: TripAttribute[] = settings
        .filter((i) => !('count' in i))
        .map((i: Attribute) => ({
            id: i.id,
            name: i.name,
        }));

    return {
        capacity,
        ...(attributes ? { attributes } : {}),
    };
};

export const spreadAttributesCapacities = (
    capacity: TripCapacity[],
    attributes?: TripAttribute[]
): AttributeOrCapacity[] => {
    return [
        ...capacity.map(
            (i) =>
                ({
                    id: i.unitId,
                    count: i.count,
                    name: '', // will be populated in the component
                } as Capacity)
        ),
        ...(attributes || []).map(
            (i) =>
                ({
                    id: i.id,
                    name: i.name, // will be populated in the component
                } as Attribute)
        ),
    ];
};

const RE_CAPACITY_MATCHER = /^(?<countPrefix>\d*)\s*(?<name>[^\d\s]*)\s*(?<countSuffix>\d*)$/;

// Refactored from vinka-booker SettingsInput
const AttributesCapacitiesInput = ({ onChange, tripTemplate }: AttributesCapacitiesInputProps) => {
    const { t, i18n } = useTranslation('SettingsInput');

    const selRef = useRef<any>();
    const { dataStore } = useRootStore();

    const [options, setOptions] = useState<AttributeOrCapacity[]>([]);
    const [value, setValue] = useState<AttributeOrCapacity[]>([]);

    const [itemCount, setItemCount] = useState(0);
    const [itemName, setItemName] = useState('');
    const [editedCapacityTimer, setEditedCapacityTimer] = useState(0);
    // eslint-disable-next-line
    const [editedCapacityCount, setEditedCapacityCount] = useState(0);

    const allowed = []; // TODO restrict allowed attributes and capacities with config

    const onTripChange = () => {
        const settings = spreadAttributesCapacities(
            tripTemplate?.capacity || [],
            tripTemplate.attributes || []
        );
        settings
            .filter((i) => !i.name)
            .forEach((i) => (i.name = (options.find((o) => o.id === i.id) || {}).name as string));
        setValue(settings);
    };

    useEffect(onTripChange, [tripTemplate]);

    useEffect(() => {
        const updated = [
            ...dataStore.capacities.map((c) =>
                // if name missing use id as name, capacity has count
                // @ts-ignore TODO use type coercion instead
                Object.assign(c, { count: 0, name: c.name || c.id })
            ),
            // @ts-ignore TODO use type coercion instead
            ...dataStore.attributes.map((c) => Object.assign(c, { name: c.name || c.id })),
            // @ts-ignore TODO use type coercion instead
        ].filter((o) => (allowed.length ? allowed.includes(o.id) : true));

        // Add i18n translations from attributes and capacities fetch from Garage API
        i18n.addResourceBundle(
            i18n.language,
            'SettingsInput',
            updated.reduce((acc: any, curr: AttributeOrCapacity) => {
                const translation = _.get(curr, `i18n[${i18n.language}]`);
                if (curr.name && translation) {
                    acc[curr.name] = translation;
                }
                return acc;
            }, {}),
            true,
            true
        );
        setOptions(updated);
        onTripChange();
    }, [dataStore.attributes, dataStore.capacities, i18n.language]);

    // try to parse the input and grab count and par of the name from it
    const onInputChange = (v: string) => {
        const { name, countPrefix, countSuffix } = (v.match(RE_CAPACITY_MATCHER) || {}).groups || {
            name: v,
        };
        const count = +(countPrefix || countSuffix || 0);

        setItemCount(count);
        setItemName(name);
    };

    const onKeyDown = (e) => {
        const capacity: Capacity | undefined = selRef.current
            ? selRef.current.select.state.focusedValue
            : undefined;

        if (capacity && capacity.count) {
            if (/^[0-9]$/.test(e.key)) {
                e.preventDefault();

                const now = Date.now();

                if (now - editedCapacityTimer > 500) {
                    capacity.count = Math.max(+e.key, 1);
                } else {
                    capacity.count = Math.max(+`${capacity.count}${e.key}`, 1);
                }

                setEditedCapacityTimer(now);
                setEditedCapacityCount(capacity.count); // trigger only to refresh the values
            }

            if (['ArrowUp', 'ArrowDown'].includes(e.key)) {
                e.preventDefault();
                capacity.count = Math.max(capacity.count + (e.key === 'ArrowUp' ? 1 : -1), 1);
                setEditedCapacityCount(capacity.count); // trigger only to refresh the values
            }

            if (['Backspace'].includes(e.key)) {
                const countString = `${capacity.count}`;

                if (countString.length > 1) {
                    e.preventDefault();
                    capacity.count = Math.max(+countString.slice(0, -1), 1);

                    setEditedCapacityTimer(Date.now());
                    setEditedCapacityCount(capacity.count); // trigger only to refresh the values
                }
            }
        } else {
            // setEditedCapacity(undefined);
            setEditedCapacityTimer(0);
            setEditedCapacityCount(0);
        }
    };

    // if there is a count in the input, leave only capacities, otherwise filer both capacities and attributes
    const filterOption = ({ data }: { data: AttributeOrCapacity }) => {
        return (
            t(data.name || '')
                .toLowerCase()
                .startsWith(itemName.toLowerCase()) &&
            (!itemCount || data.hasOwnProperty('count'))
        );
    };

    // do not compare objects here as they are different! compare by id
    const isOptionSelected = (
        i: AttributeOrCapacity,
        selections: OptionsType<AttributeOrCapacity>
    ) => {
        return selections.some((s) => i.id === s.id);
    };

    const formatOptionLabel = (o: AttributeOrCapacity, { context }) => {
        const isCapacity = o.hasOwnProperty('count');

        if (context === 'value') {
            // `value` context means that the item is displayed as an item of the input, so skip highlighting and show actual count
            const prefix = isCapacity ? `${(o as Capacity).count}⨯` : '';

            return `${prefix}${t(o.name)}`;
        } else {
            // otherwise this is a dropdown menu label, show highlighting and also add count from the input
            const prefix = isCapacity ? `${itemCount || 1}⨯` : '';

            return (
                <Highlighter
                    autoEscape={true} // important! otherwise some characters like `+` and `*` will cause an exception
                    searchWords={[itemName]}
                    textToHighlight={`${prefix}${t(o.name)}`}
                />
            );
        }
    };

    const changeHandler = (changedValue, action) => {
        if (action.action === 'select-option') {
            const addedOption = changedValue.pop();

            // add not the original object, but its copy with the modified count property if needed
            changedValue.push({
                ...addedOption,
                ...(addedOption.hasOwnProperty('count') ? { count: itemCount || 1 } : {}),
            });
        }

        const changedTrip = Object.assign(
            {},
            tripTemplate,
            groupAttributesCapacities(changedValue)
        );
        onChange(changedTrip.attributes || [], changedTrip.capacity || []);
    };

    return (
        <Select
            ref={selRef}
            classNamePrefix="react-select"
            isMulti={true}
            isClearable={true}
            isSearchable={true}
            openMenuOnFocus={true}
            tabSelectsValue={false}
            blurInputOnSelect={false}
            closeMenuOnSelect={false}
            // eslint-disable-next-line
            onOptionHover={console.log}
            hideSelectedOptions={true}
            onInputChange={onInputChange}
            onKeyDown={onKeyDown}
            filterOption={filterOption}
            isOptionSelected={isOptionSelected}
            formatOptionLabel={formatOptionLabel}
            onChange={changeHandler}
            // TODO localize, a bit more difficult here since we're using a separate namespace with resource bundles etc.
            placeholder={'Valitse...'}
            getOptionValue={(i) => `${i.id}`} // should be here! and should be unique! for removing
            options={options}
            value={value}
        />
    );
};

export default observer(AttributesCapacitiesInput);
