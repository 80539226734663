import * as _ from 'lodash';
import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import styles from './Accounts.module.css';

import config from '../../infra/config';
import { IAccount, Nullish } from '../../types';
import { selectStyles } from '../../utils';
import AddressInput from '../AddressInput';
import AttributesCapacitiesInput from '../AttributesCapacitiesInput/AttributesCapacitiesInput';

interface IOnChange {
    (accounts: IAccount[]): void;
}
type ComponentParams = {
    accounts: IAccount[];
    onChange: IOnChange;
};

const Accounts = ({ accounts, onChange }: ComponentParams) => {
    const { t, i18n } = useTranslation();
    const tExcessTypes = i18n.getFixedT(i18n.language, 'ExcessTypes');
    const [selectedAccount, setSelectedAccount] = useState<Nullish<IAccount>>(null);

    const locales = Object.keys(config.app.excessTypes).reduce((acc, curr) => {
        acc[curr] = config.app.excessTypes[curr].i18n[i18n.language];
        return acc;
    }, {});
    i18n.addResourceBundle(i18n.language, 'ExcessTypes', locales, true, true);
    const excessTypeOptions = Object.keys(config.app.excessTypes).reduce(
        (acc: any, curr: string) => {
            if (config.app.excessTypes[curr].allowEdit) {
                acc.push({
                    value: curr,
                    label: tExcessTypes(curr),
                });
            }
            return acc;
        },
        []
    );

    useEffect(() => {
        if (accounts) {
            // If customer does not change ->
            // do not change selected account on updates
            let account = accounts.find(
                (a) => selectedAccount?.customer === a.customer && selectedAccount?.id === a.id
            );
            if (!account) {
                account = accounts[0];
            }
            setSelectedAccount(account);
        }
    }, [accounts]);

    const handleChange = (partial: Partial<IAccount>) => {
        if (accounts && selectedAccount) {
            const account = accounts.find((a) => selectedAccount?.id === a.id) as IAccount;
            onChange(
                accounts.map((a) => {
                    if (a.id === account.id) {
                        return _.merge({}, a, partial);
                    }
                    return a;
                }, accounts)
            );
        }
    };

    const accountHandler = (e) => {
        const account = accounts.find((a) => a.id === e.value) || accounts[0];
        setSelectedAccount(account);
    };

    const getAccountLabel = (a: IAccount | null) => {
        const aAllowance = _.get(a, 'travelCredit.allowance');
        const aExcessType = _.get(a, 'travelCredit.excessType');
        let label = a?.tripType || '';
        // isNil
        if (aAllowance != null) {
            label +=
                (label.length ? ' ' : '') +
                (aAllowance === 1 ? t('Matkaoikeus') : t('Ei matkaoikeutta'));
        }
        if (aExcessType) {
            label += (label.length ? ' ' : '') + tExcessTypes(aExcessType);
        }
        return label.length ? label : a?.id;
    };
    const excessType = selectedAccount?.travelCredit?.excessType || '';
    return (
        <Form.Group className={styles.formGroup}>
            <div className={styles.separator}>{t('form.customer.accounts')}</div>
            <Row>
                <Col sm={6}>
                    <Form.Label>{t('form.customer.label.accountId')}</Form.Label>
                    <Select
                        className="basic-single"
                        classNamePrefix="select"
                        value={{
                            value: selectedAccount?.id,
                            label: getAccountLabel(selectedAccount),
                        }}
                        styles={selectStyles as any}
                        isDisabled={false}
                        isLoading={false}
                        isClearable={false}
                        isSearchable={true}
                        options={(accounts || []).map((a) => ({
                            value: a.id,
                            label: getAccountLabel(a),
                        }))}
                        onChange={accountHandler}
                    />
                </Col>
                {selectedAccount?.type === 'TAKSO' && (
                    <>
                        <Col sm={6}>
                            <Form.Label>{t('form.customer.label.excessType')}</Form.Label>
                            {!excessType || config.app.excessTypes[excessType]?.allowEdit ? (
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    value={{
                                        value: excessType,
                                        label: tExcessTypes(excessType || ''),
                                    }}
                                    styles={selectStyles as any}
                                    isDisabled={false}
                                    isLoading={false}
                                    isClearable={false}
                                    isSearchable={true}
                                    options={excessTypeOptions}
                                    onChange={(e) => {
                                        const eType = e?.value as string;
                                        if (eType) {
                                            handleChange({
                                                travelCredit: { excessType: eType },
                                            });
                                        }
                                    }}
                                />
                            ) : (
                                <Form.Control
                                    disabled={true}
                                    value={
                                        tExcessTypes(
                                            _.get(selectedAccount, 'travelCredit.excessType'),
                                            ''
                                        ) +
                                        '-' +
                                        _.get(selectedAccount, 'travelCredit.transactionId', '')
                                    }
                                    type="text"
                                />
                            )}
                        </Col>
                    </>
                )}
                {/* <Col>
                    <Form.Label>{t('form.customer.label.accountType')}</Form.Label>
                    <Form.Control
                        value={selectedAccount?.type    }
                        onChange={(e) => handleChange({ type: e.target.value })}
                        type="text"
                    />
                </Col> */}
            </Row>
            <Row>
                <Col sm={2}>
                    <Form.Group className={styles.formGroup}>
                        <Form.Label>{t('form.customer.label.myTaxi')}</Form.Label>
                        <Form.Check
                            checked={!!selectedAccount?.myTaxi}
                            onChange={() => {
                                handleChange({ myTaxi: !selectedAccount?.myTaxi });
                            }}
                        />
                    </Form.Group>
                </Col>
                <Col sm={2}>
                    <Form.Group className={styles.formGroup}>
                        <Form.Label>{t('form.customer.label.prohibitSharing')}</Form.Label>
                        <Form.Check
                            checked={!selectedAccount?.tripTemplate?.sharing}
                            onChange={() => {
                                handleChange({ sharing: !selectedAccount?.tripTemplate?.sharing });
                            }}
                        />
                    </Form.Group>
                </Col>
            </Row>
            {/* TRIP TEMPLATE */}
            <Form.Group className={styles.formGroup}>
                <Form.Group className={styles.formGroup}>
                    <Row>
                        <Col md={6}>
                            <Form.Label>{t('form.tripTemplate.label.pickupAddress')}</Form.Label>
                            <AddressInput
                                value={selectedAccount?.tripTemplate?.start || null}
                                onChange={(e) => {
                                    handleChange({ tripTemplate: { start: e || undefined } });
                                }}
                            />
                        </Col>
                        <Col md={6}>
                            <Form.Label>{t('form.tripTemplate.label.dropAddress')}</Form.Label>
                            <AddressInput
                                value={selectedAccount?.tripTemplate?.end || null}
                                onChange={(e) => {
                                    handleChange({ tripTemplate: { end: e || undefined } });
                                }}
                            />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group className={styles.formGroup}>
                    <Form.Label>{t('form.tripTemplate.label.attributesCapacities')}</Form.Label>
                    <AttributesCapacitiesInput
                        onChange={(attributes, capacity) => {
                            handleChange({ tripTemplate: { attributes, capacity } });
                        }}
                        tripTemplate={selectedAccount?.tripTemplate || {}}
                    />
                </Form.Group>
            </Form.Group>
        </Form.Group>
    );
};

export default Accounts;
