import i18n from 'i18next';
import React from 'react';
import ReactDOM from 'react-dom';
import { initReactI18next } from 'react-i18next';

import { Auth0Provider } from '@auth0/auth0-react';

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Layout from './components/Layout/Layout';
import config from './infra/config';
import en from './locales/en';
import fi from './locales/fi';
import RootStoreProvider from './providers/RootStoreProvider';

const locale = localStorage.getItem('locale') || config.localization.locale;
i18n.use(initReactI18next).init({
    resources: { fi: { translation: fi }, en: { translation: en } },
    lng: locale,
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false, // react already safes from xss
    },
});

ReactDOM.render(
    <React.StrictMode>
        <Auth0Provider
            domain={config.auth0.domain}
            clientId={config.auth0.clientId}
            audience={config.auth0.audience}
        >
            <RootStoreProvider>
                <Layout />
            </RootStoreProvider>
        </Auth0Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
